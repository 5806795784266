import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchService} from '../search.service';
import {SearchContext} from '../shared/search-context';
import { SwirlService } from '../swirl.service'
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-sort-search',
  templateUrl: './sort-search.component.html',
  styleUrls: ['./sort-search.component.css']
})
export class SortSearchComponent implements OnInit {

  sortForm: FormGroup;
  searchContext: SearchContext = new SearchContext();
  sortings = [
    {value: 'RelevancyMixer', name: 'Relevancy'},
    {value: 'DateMixer', name: 'Date'},
    {value: 'RoundRobinMixer', name: 'Top Pick'}
    // 'ConfidenceMixer' option will be conditionally added
  ];
  isLoading: boolean;
  isShowing: boolean;
  numFound: number;
  confidence_mixer: boolean;

  constructor(private fb: FormBuilder, private searchService: SearchService, private swirl: SwirlService, private authService: AuthService) {
    this.sortForm = this.fb.group({
      selectedSorting: [this.searchService.getFilter('result_mixer')?.split(':')?.[1], Validators.required]
    });
    this.isLoading = false;
    this.isShowing = false;
    this.numFound = 0;
    this.confidence_mixer = authService.getConfidenceMixerStatus(); // Use the value from the auth service
  }

  ngOnInit(): void {
    // Dynamically add 'Confidence' option if enabled
    if (this.confidence_mixer) {
      this.sortings.push({value: 'RelevancyConfidenceMixer', name: 'Confidence'});
    }

    this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    this.searchService.isSearched.subscribe(isSearched => this.isShowing = isSearched)
    this.searchService.currentResults.subscribe(results => {
      this.numFound = results.numFound;
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if (isErased) {
        this.searchService.putFilterWithoutSearch('result_mixer', `result_mixer:${this.sortings[0].value}`);
      }
    });
  }

  onSelectionChange(event: any): void {
    let filter: string = event.value;
    this.searchService.clearSelectedItems();
    if (filter == null) {
      this.searchService.removeFilter('result_mixer');
    } else {
      this.searchService.putFilter('result_mixer', `result_mixer:${filter}`);
    }
    if (filter !== null) {
      const selectedSortingControl = this.sortForm.get('selectedSorting');
      if (selectedSortingControl) {
        selectedSortingControl.setValue(filter);
      }    
    }
  }
}
