import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root', // Makes this service available across the whole app
})
export class CookieConsentService {
  private cookieConsent: any;

  // Initialize the cookie consent banner
  initCookieConsent(): void {
    // Check if CookieConsent is defined on the window object
    const CookieConsent = (window as any).CookieConsent;
    if (!CookieConsent) {
      console.error('CookieConsent is not defined. Please ensure the library is properly loaded.');
      return;
    }

    // Initialize the CookieConsent banner
    this.cookieConsent = CookieConsent.init({
      autoOpen: true, // Automatically open the banner
      position: 'bottom', // Banner position
      theme: 'classic', // Use the classic theme
      palette: {
        popup: {
          background: '#0060a8',
          text: '#ffffff',
          link: '#ffffff',
        },
        button: {
          background: '#ffffff',
          text: '#000000',
          border: 'transparent',
        },
      },
      type: 'info', // Info banner
      content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        allow: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: 'https://swirlaiconnect.com/privacy-policy',
      },
    });

    // Attach event listeners to the banner
    this.cookieConsent.on('popupOpen', () => {
      console.log('Cookie consent popup opened.');
    });

    this.cookieConsent.on('popupClose', () => {
      console.log('Cookie consent popup closed.');
    });

    this.cookieConsent.on('statusChanged', (status: string) => {
      console.log(`Cookie consent status changed: ${status}`);
    });
  }

  // Expose the cookie consent object for advanced usage
  getCookieConsent(): any {
    return this.cookieConsent;
  }
}
