import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  private apiUrl = '/api/swirl';
  private tokenKey = 'swirl-token';
  private chatStatusKey = 'swirl-chat-status';
  private AIProviderStatusKey = 'swirl-ai-provider-status';
  private ConfidenceMixerStatusKey = 'swirl-confidence-mixer-status';

  constructor(private http: HttpClient) {}


  login(username: string, password: string): any {
    const csrfToken = this.getCookie('csrftoken'); // get the CSRF token from the cookie
    const headers = new HttpHeaders().set('X-CSRFToken', csrfToken); // include the CSRF token in the request headers
    return this.http.post(`${this.apiUrl}/login/`, { username, password }, { headers })
  }

  oidcLogin(token: string): any {
    const csrfToken = this.getCookie('csrftoken'); // get the CSRF token from the cookie
    const headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken) // include the CSRF token in the request headers
      .set('OIDC-Token', `Bearer ${token}`)
    return this.http.post(`${this.apiUrl}/oidc_authenticate/`, {}, { headers })

  }

  logout(): any {
    const csrfToken = this.getCookie('csrftoken');
    let headers = new HttpHeaders()
      .set('X-CSRFToken', csrfToken)
      .set('Authorization', 'Token ' + this.getToken())
      .set('Content-Type', 'application/json')
    return this.http.post(`${this.apiUrl}/swirl-logout/`, {}, { headers })
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  getChatStatus(): boolean {
    return localStorage.getItem(this.chatStatusKey) == 'true';
  }

  setChatStatus(status: boolean): void {
    localStorage.setItem(this.chatStatusKey, status.toString());
  }

  getPromptsStatus(): boolean {
    return localStorage.getItem(this.AIProviderStatusKey) == 'true';
  }

  setPromptsStatus(status: boolean): void {
    localStorage.setItem(this.AIProviderStatusKey, status.toString());
  }

  getAIProviderStatus(): boolean {
    return localStorage.getItem(this.AIProviderStatusKey) == 'true';
  }

  setAIProviderStatus(status: boolean): void {
    localStorage.setItem(this.AIProviderStatusKey, status.toString());
  }

  getConfidenceMixerStatus(): boolean {
    return localStorage.getItem(this.ConfidenceMixerStatusKey) == 'true';
  }

  setConfidenceMixerStatus(status: boolean): void {
    localStorage.setItem(this.ConfidenceMixerStatusKey, status.toString());
  }

  getUser(): string | null {
    return localStorage.getItem('user');
  }

  setUser(user: string): void {
    localStorage.setItem('user', user);
  }

  getCookie(name: string): string {
    let cookieValue = '';
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  setCookie(name: string, value: string, minutes: number): void {
    let expires = '';
    if (minutes) {
        const date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/;`
  }

  redirectToAuth(idp: string) {
    const token = this.getToken()
    if(token) {
      const path = window.location.pathname + window.location.search
      this.setCookie('Token', token, 20);
      this.setCookie('Callback-Path', path, 20);
      window.location.href = `/api/swirl/${idp.toLowerCase()}-idp-auth`
    }
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }
}