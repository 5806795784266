import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
import { LocalStorageService } from '../local-storage.service';
import { ChatService } from '../chat.service';
import { SwirlService } from '../swirl.service';

@Component({
  selector: 'app-chat-spyglass',
  templateUrl: './spyglass-chat.component.html',
  styleUrls: ['./spyglass-chat.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpyglassChatComponent implements OnInit, OnDestroy {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  backgroundImage: string = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
  isLoading: boolean = false;
  sources = [
    { value: 'default', name: 'All sources', connector: 'default' }
  ];
  isEnabledCharts: boolean = true;
  isConversationStarted: boolean = false;
  messages: any[] = [];
  chatId: any = null;
  isAIProviderExists: boolean = true;

  private cookieConsentInstance: any;

  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private chatService: ChatService,
    private router: Router,
    private authService: AuthService,
    private swirlService: SwirlService,
    private titleService: Title
  ) {
    this.isEnabledCharts = true;
    this.isConversationStarted = false;
    this.isAIProviderExists = this.authService.getAIProviderStatus();
    this.titleService.setTitle('SWIRL AI Co-Pilot');
  }

  private updateUrlWithChatId(chatId: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        chat_id: chatId
      },
      queryParamsHandling: 'merge', // merge with other query params
    });
  }

  initCookieConsent(): void {
    // Access cookieconsent directly from the global scope
    const cookieconsent = (window as any).cookieconsent;
  
    if (!cookieconsent) {
      console.error('CookieConsent is not defined. Ensure that cookieconsent.min.js is loaded correctly.');
      return;
    }
  
    try {
      this.cookieConsentInstance = cookieconsent.initialise({
        palette: {
          popup: {
            background: "#0060a8",
            text: "#ffffff",
            link: "#ffffff"
          },
          button: {
            background: "#ffffff",
            text: "#000000",
            border: "transparent"
          }
        },
        theme: "classic",
        type: "info", // Ensure "opt-in" or "opt-out" isn't used if you want just "Got it!"
        revokable: false,
        content: {
          message: "This website uses cookies to ensure you get the best experience on our website.",
          allow: "Got it!",
          link: "Learn more",
          href: "https://swirlaiconnect.com/privacy-policy"
        }
      });

      // Register event listeners
      if (this.cookieConsentInstance?.on) {
        this.cookieConsentInstance.on("statusChanged", (status: string) => {
          console.log(`Cookie consent status changed to: ${status}`);
          this.handleConsentChange(status);
        });
      
        this.cookieConsentInstance.on("error", (err: any) => {
          console.error("Cookie consent error:", err);
        });
      } else {
        console.warn("CookieConsent instance does not support event listeners. Skipping listener registration.");
      }
    } catch (error) {
      console.error('Failed to initialize CookieConsent:', error);
    }
  }

  handleConsentChange(status: string): void {
    switch (status) {
      case 'allow':
        console.log("User consented to all cookies.");
        document.cookie = 'cookies_info_swirl_visited=true; path=/; max-age=' + (365 * 24 * 60 * 60);
        break;
      case 'deny':
        console.log("User denied cookies.");
        break;
      case 'dismiss':
        console.log("User dismissed the cookie popup.");
        break;
    }
  }


  ngOnInit(): void {
    this.swirlService.getIsAIProviderExistsStatus().subscribe((data) => {
      if (data?.status) {
        this.authService.setAIProviderStatus(data.status === 'True');
        this.isAIProviderExists = data.status === 'True';
      }
    });

    if (!document.body.classList.contains("dark-theme") && this.mode === 'Dark') {
      document.body.classList.toggle("dark-theme");
    }
    document.body.classList.toggle("chat");

    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light';
      this.backgroundImage = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
    });

    // Initialize cookie consent banner
    this.initCookieConsent();

    this.route.queryParams.subscribe(params => {
      this.chatId = params['chat_id'] || null;
      if (this.chatId) {
        this.chatService.setChatId(this.chatId);
        this.messages = [...this.chatService.getMessages(this.chatId)];
        this.chatService.getMessagesObservable(this.chatId).subscribe(messages => {
          this.isConversationStarted = true;
          if (messages.length == 0 && this.messages.length == 0)
            this.messages = [...this.chatService.getMessages(this.chatId)];
          if (this.messages.length < messages.length)
            this.messages = [...messages];
        });
        this.updateUrlWithChatId(this.chatId); // Update URL with chat_id
      }
    });

    this.chatService.isConversationStarted$.subscribe((isConversationStarted) => {
      this.isConversationStarted = isConversationStarted;
      this.chatId = this.chatService.getChatId();
      this.chatService.getMessagesObservable(this.chatId).subscribe(messages => {
        if (messages.length == 0 && this.messages.length == 0)
          this.messages = [...this.chatService.getMessages(this.chatId)];
        if (this.messages.length < messages.length)
          this.messages = [...messages];
      });
      this.updateUrlWithChatId(this.chatId); // Update URL with chat_id
    });

    this.chatService.eraseEvent.subscribe((isErased) => {
      if (isErased) {
        this.router.navigate(['/chat']);
        this.chatService.changeEraseEvent(false);
        this.isConversationStarted = false;
        this.messages = [];
        this.chatService.clearChatId();
      }
    });
  }

  ngOnDestroy() {
    // Clean up if necessary
    if (this.cookieConsentInstance) {
      this.cookieConsentInstance.destroy();
    }
  }

  handleLogoClick() {
    this.chatService.changeEraseEvent(true);
  }
}
