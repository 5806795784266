<div *ngIf="!isLoading && isShowing">
  <form class="col form-group" [formGroup]="sortForm">
    <span class="sort-label">VIEW BY:</span>
    <mat-form-field appearance="outline">
      <mat-select class="mat-select mat-select-value" formControlName="selectedSorting" (selectionChange)="onSelectionChange($event)">
        <mat-option [class.mat-option]="true" *ngFor="let sorting of sortings" [value]="sorting.value">
          {{sorting.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>