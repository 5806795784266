import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ChatProfileBoxComponent } from './chat-profile-box/chat-profile-box.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SpyglassChatComponent } from './spyglass-chat/spyglass-chat.component';
import { CustomFieldDirective } from './custom-field.directive';
import { ChatComponent } from './chat/chat.component';
import { ChatAuthorshipFieldComponent } from './chat-authorship-field/chat-authorship-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AccordionModule } from 'primeng/accordion';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfigService } from './config.service';
import { MsalAccessService } from '../auth/msal-service';
import { OauthService } from '../auth/oauth2-service';
import { WebSocketService } from './websockets.service';
import { timer } from 'rxjs';
import { switchMap, filter, take, first, tap } from 'rxjs/operators';
import { ConfigResponse } from '../spyglass-search/shared/config-response';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';

// Import the CookieConsentService from the shared directory
import { CookieConsentService } from '../shared/cookie-consent.service';

export function initializeApp(
  configService: ConfigService,
  msalService: MsalAccessService,
  oauthService: OauthService,
  webSocketService: WebSocketService
) {
  return () => {
    const pollingInterval = 1000; // Interval between polling attempts (in milliseconds)

    return timer(0, pollingInterval)
      .pipe(
        tap(() => console.log('Polling for config...')),
        switchMap(() => configService.getConfig('default')),
        tap((configResponse) => console.log('Config response:', configResponse)),
        filter(
          (configResponse): configResponse is ConfigResponse =>
            configResponse !== null && configResponse.msalConfig !== null
        ),
        take(1),
        first() // Ensures that the Observable completes after the first value
      )
      .toPromise()
      .then((configResponse) => {
        if (!configResponse) {
          console.error('Config response is undefined.');
          return; // Early return if configResponse is undefined
        }
        const oauthConf = configResponse.oauthConfig;
        const msalConf = configResponse.msalConfig;
        const shouldUseTokenFromOauth = configResponse.shouldUseTokenFromOauth;
        const webSocketConf = configResponse.webSocketConfig;
        const microsoftGalaxyOauth = configResponse.microsoftGalaxyOauth;
        if (oauthConf) oauthService.loadConfig(oauthConf);
        if (webSocketConf) webSocketService.loadConfig(webSocketConf);
        msalService.loadConfig(msalConf, shouldUseTokenFromOauth, microsoftGalaxyOauth);
      });
  };
}

@NgModule({
  declarations: [
    ChatBoxComponent,
    ChatProfileBoxComponent,
    SpyglassChatComponent,
    CustomFieldDirective,
    ChatComponent,
    ChatAuthorshipFieldComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    AccordionModule,
    ButtonModule
  ],
  exports: [
    ChatProfileBoxComponent,
    SpyglassChatComponent,
    CommonModule,
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    ConfigService,
    MsalAccessService,
    OauthService,
    WebSocketService,
    CookieConsentService, // Add CookieConsentService to the providers array
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, MsalAccessService, OauthService, WebSocketService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ]
})
export class SpyglassChatModule {}
