import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../search.service';
import { WebSocketService } from '../websockets.service';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit, OnDestroy {
  
  text: string = ''
  provider: string = ''
  nameMappings: {[key: string]: any} = {
    'CHATGPT': 'ChatGPT'
  }
  isLoading: boolean = true
  isShowing: boolean = false
  lastSearchId: any;
  ragItems: any;
  isRagParamInUrl: any;
  ragTimeout: any;

  private webSocketConnection: any;
  private webSocketSubscription: Subscription;

  constructor(private authService: AuthService, private searchService: SearchService, private webSocketService: WebSocketService, private route: ActivatedRoute) {
    this.text = ''
    this.provider = ''
    this.lastSearchId = false
    this.ragItems = []
    this.webSocketSubscription = new Subscription();
    this.isRagParamInUrl = false
    this.ragTimeout = 0
  }

  isNumberOrStringNumber(value: any): number | null {
    // Check if the value is already a number
    if (typeof value === 'number' && isFinite(value)) {
        return value; // Return as is
    }
    // If it's a string, check if it can be successfully converted to a number
    if (typeof value === 'string') {
        const parsedNumber = parseInt(value, 10); // Convert string to number
        if (!isNaN(parsedNumber) && isFinite(parsedNumber)) {
            return parsedNumber; // Return parsed number
        }
    }
    // Return null if the value is neither a valid number nor a parsable string
    return null;
  }

  destroyWebSocketConnection() {
    if (this.webSocketConnection) {
      console.log("DESTROY SOCKET")
      this.webSocketConnection.complete();
      this.webSocketConnection = null;
    }
    this.webSocketService.disable()
  }

  async createWebSocketConnection(searchId: any, ragItems: any) {
    this.destroyWebSocketConnection()
    console.log("CREATE SOCKET")
    this.webSocketConnection = await this.webSocketService.init(this.authService.getToken(), searchId, ragItems)
  }

  initiateWebSocketDataFlow() {
    console.log("Initiating WebSocket data flow");

    this.isLoading = true;
    this.webSocketService.time.next(0);
    this.searchService.websocketLoading.next(true);
    const startTime = Date.now();
    this.webSocketService.send('');

    // Validate and parse the timeout value
    let websocketTimeout = this.isNumberOrStringNumber(this.webSocketService.timeout);
    if (!websocketTimeout) {
        throw new Error("Invalid WebSocket timeout value"); // Handle missing/invalid timeout
    }

    // If ragTimeout is provided, override the default timeout
    const ragTimeoutParsed = this.isNumberOrStringNumber(this.ragTimeout);
    if (ragTimeoutParsed !== null) {
        websocketTimeout = ragTimeoutParsed * 1000; // Convert seconds to milliseconds
    }

    this.webSocketService
      .receive()
      .pipe(timeout(websocketTimeout))
      .subscribe({
        next: ({ message, ai_model }) => {
          const responseTime = Date.now() - startTime;
          this.webSocketService.time.next(responseTime);
          this.isLoading = false;
          this.searchService.websocketLoading.next(false);

          message = this.sanitizeText(message);
          this.text = message;
          if (ai_model) {
            this.webSocketService.aiModelChange.next(ai_model);
          }
          this.destroyWebSocketConnection();
        },
        error: (error) => {
          if (error.name === 'TimeoutError') {
            this.webSocketService.send('stop');
            this.text = this.webSocketService.timeoutText;
            this.isLoading = false;
            this.searchService.websocketLoading.next(false);
            this.destroyWebSocketConnection();
          } else {
            console.error("WebSocket error:", error);
          }
        }
      });
  }

  sanitizeText(text: string) {
    return text.replace(
      /<a class="question-link">([\s\S]*?)<\/a>/g,
      (_match: any, innerContent: string) => {
        const sanitizedContent = innerContent.trim().replace(/\s+/g, ' ').replace(/<[^>]*>/g, '').replace('?', '');
        let link = '/galaxy/search?q=' + sanitizedContent

        if(this.searchService.searchInfo.value.search.searchprovider_list) {
          const providers = this.searchService.searchInfo.value.search.searchprovider_list.join(',')
          link = link + '&providers=' + providers
        }
        link = link + '&rag=true'
        return `<a class="question-link" href="${link}">${innerContent}</a>`;
      }
    );
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.isRagParamInUrl = params.get('rag')
      this.ragTimeout = params.get('rag_timeout')
    })
    this.searchService.selectedRagItems.subscribe((ragItems: any) => this.ragItems = ragItems)
    this.webSocketSubscription = this.webSocketService.webSocketChanged.subscribe(async ([isEnabled, isNewSearch]) => {
      if(isNewSearch)
        this.lastSearchId = false
      // Disable 'Generate AI response'
      if(!isEnabled) {
        this.isShowing = false
        console.log("Disable 'Generate AI response'")
        this.destroyWebSocketConnection()
      }
      // Enable 'Generate AI response'
      else {
        console.log("Enable 'Generate AI response'")
        if (this.lastSearchId) {
          await this.createWebSocketConnection(this.lastSearchId, this.ragItems)
          this.isShowing = true
          this.initiateWebSocketDataFlow()
        }
      }
    })
    this.searchService.currentSearchInfo.subscribe(async (info) => {
      if(info?.search?.id && !this.lastSearchId) {
        this.lastSearchId = info.search.id
        if(this.webSocketService.isEnabled()) {
          console.log('WebSocket enabled')
          await this.createWebSocketConnection(info.search.id, this.ragItems)
          this.isShowing = true
          this.initiateWebSocketDataFlow()
        }
      }
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if(isErased) {
        this.isLoading = true
        this.isShowing = false
        this.lastSearchId = false
        this.webSocketService.changeStatus(false, true)
        this.ragItems = []
        this.webSocketService.time.next(0)
      }
    })
    this.searchService.isNewSearch.subscribe(isNewSearch => {
      if(isNewSearch) {
        this.isLoading = true
        this.isShowing = false
      }
    })
    this.searchService.isLoading.subscribe(isLoading => {
      this.lastSearchId = !isLoading ? this.lastSearchId : false
    });
    this.searchService.isNewSearch.subscribe(isNewSearch => this.webSocketService.time.next(0))
  }

  ngOnDestroy() {
    this.destroyWebSocketConnection()
    if(this.webSocketSubscription)
      this.webSocketSubscription.unsubscribe()
  }

}