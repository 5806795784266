import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../config.service';
import { SearchService } from '../search.service';
import { SwirlService } from '../swirl.service';
import { LocalStorageService } from '../local-storage.service';
import * as CookieConsent from 'cookieconsent';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-spyglass',
  templateUrl: './spyglass.component.html',
  styleUrls: ['./spyglass.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpyglassComponent implements OnInit, OnDestroy {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  backgroundImage: string = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
  isLoading: boolean = false;
  sources = [
    { value: 'default', name: 'All sources', connector: 'default' }
  ];
  prompts = [
    { value: 'default', name: 'Swirl Prompt' }
  ];
  isEnabledCharts: boolean = true;
  promptsExists: boolean = true;

  private cookieConsentInstance: any;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private searchService: SearchService,
    private swirl: SwirlService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private titleService: Title
  ) {
    this.isEnabledCharts = true;
    this.titleService.setTitle('SWIRL Unified Search');
    this.promptsExists = this.auth.getPromptsStatus();
  }

  handleConsentChange(status: string): void {
    // Handle consent status changes
    switch (status) {
      case 'allow':
        console.log("User consented to all cookies.");
        document.cookie = 'cookies_info_swirl_visited=true; path=/; max-age=' + (365 * 24 * 60 * 60);
        // Enable cookies or related features
        break;
      case 'deny':
        console.log("User denied cookies.");
        // Disable cookies or related features
        break;
      case 'dismiss':
        console.log("User dismissed the cookie popup.");
        // Handle dismissed status (optional)
        break;
    }
  }

  initCookieConsent(): void {
    // Access cookieconsent directly from the global scope
    const cookieconsent = (window as any).cookieconsent;
  
    if (!cookieconsent) {
      console.error('CookieConsent is not defined. Ensure that cookieconsent.min.js is loaded correctly.');
      return;
    }
    
    try {
      this.cookieConsentInstance = cookieconsent.initialise({
        palette: {
          popup: {
            background: "#0060a8",
            text: "#ffffff",
            link: "#ffffff"
          },
          button: {
            background: "#ffffff",
            text: "#000000",
            border: "transparent"
          }
        },
        theme: "classic",
        type: "info", // Ensure "opt-in" or "opt-out" isn't used if you want just "Got it!"
        revokable: false, // Disable the reopen button
        content: {
          message: "This website uses cookies to ensure you get the best experience on our website.",
          allow: "Got it!", // Only the "allow" button should be here
          link: "Learn more",
          href: "https://swirlaiconnect.com/privacy-policy"
        }
      });
  
      // Register event listeners
      if (this.cookieConsentInstance?.on) {
        this.cookieConsentInstance.on("statusChanged", (status: string) => {
          console.log(`Cookie consent status changed to: ${status}`);
          this.handleConsentChange(status);
        });
      
        this.cookieConsentInstance.on("error", (err: any) => {
          console.error("Cookie consent error:", err);
        });
      } else {
        console.warn("CookieConsent instance does not support event listeners. Skipping listener registration.");
      }
    } catch (error) {
      console.error('Failed to initialize CookieConsent:', error);
    }
  }

  ngOnInit(): void {
    // Handle browser back button logic
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.handleBackButton();
        }
      }
    });

    // Dark mode toggle
    if (!document.body.classList.contains("dark-theme") && this.mode === 'Dark') {
      document.body.classList.toggle("dark-theme");
    }

    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light';
      this.backgroundImage = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
    });

    // Fetch sources and prompts
    this.swirl.getSources().subscribe(async response => {
      response.filter((source: any) => source.active).forEach((source: any) => {
        this.sources.push({
          value: source.id,
          name: source.name,
          connector: source.connector
        });
      });

      const params = this.route.snapshot.queryParamMap;
      const scope = params.get('scope') || 'default';

      this.configService.getConfig(scope).subscribe(config => {
        if (config && !this.isLoading) {
          this.hasFacets = config.facetLayout != null;
          this.type = config['type'];
        }
      });

      if (this.promptsExists) {
        this.swirl.getPrompts().subscribe(response => {
          response.forEach((prompt: any) => {
            this.prompts.push({
              value: prompt.id,
              name: this.formatPromptName(prompt.name),
            });
          });
        });
      }

      this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    });

    this.searchService.isEnabledCharts.subscribe(isEnabledCharts => this.isEnabledCharts = isEnabledCharts);

    // Initialize the cookie consent banner
    this.initCookieConsent();
  }

  formatPromptName(name: string): string {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  ngOnDestroy() {
    // Clean up the cookie consent instance
    if (this.cookieConsentInstance) {
      this.cookieConsentInstance.destroy();
    }
  }

  handleBackButton(): void {
    this.searchService.changeEraseEvent(true);
  }

  handleLogoClick(): void {
    this.searchService.changeEraseEvent(true);
  }
}
